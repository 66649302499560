import {
  globalpaymentsInitiatePayment,
  globalPaymentsGenerateToken,
  GlobalpaymentSaveCard,
} from '@kfc-global/react-shared/redux/Actions/PaymentsAction';
import {
  paymentTrancationSuccessHandler,
  paymentTrancationErrorHandler,
  updateClickPlaceOrderState,
  checkPaymentMethod,
  getTenderIcon,
  validateBillingAddress,
  validateBillingPostalCode,
  checkOutDefaultAddressSelected,
} from 'common/utilities/PaymentUtils';
import { PAYMENT_TENDER_TYPES, PROD } from 'common/constants/SharedConstants';
import AuthManager from '@kfc-global/react-shared/auth/AuthManager';
import {
  loadScript,
  redirectToPaymentFailurePage,
  redirectToAccountPage,
  isCurrentPageRedirectFromRNA,
  isPickupDisposition,
  isDeliveryDisposition,
  redirectToProcessingPage,
} from 'common/utilities/utils';
import { config } from '@kfc-global/react-shared/config/config.utils';
import { APP_ENVIRONMENT, GLOBAL_PAYMENTS_3DS_FILE_URL } from '@kfc-global/react-shared/config/config.constants';
import networkConfig from '@kfc-global/react-shared/network/config';
import PAYMENT_CONSTANTS from 'organisms/Payment/constants/PaymentConstants';
import { translateWithI18Next } from '@kfc-global/kfc-i18n/lib';
import { TRANSLATE_MAPPING_KEY } from 'common/constants/i18nTranslateKeys';
import { getCustomerInfo } from '@kfc-global/react-shared/redux/Actions/OrderservAction';
import CONTEXT_REDUCER_MAPPING_KEY from 'common/constants/ContextReducerKeys';

const {
  GLOBAL_PAYMENTS_CONSTANTS,
  GLOBAL_PAYMENTS_BROWSER_DATA_VALUES,
  PAYMENT_CREDIT_DEBIT_CARD_VIEW_TEMPLATES,
  SINGLE,
  GLOBAL_PAYMENTS_VERIFICATION_ERROR_CODE,
} = PAYMENT_CONSTANTS;

const { POSTAL_CODE, ADDRESS } = PAYMENT_CREDIT_DEBIT_CARD_VIEW_TEMPLATES;

const { gloablPaymentCheckVersionUrl, gloablPayment3DSIntitiateAuthUrl } = networkConfig;

export const getGlobalPaymentsEnvironment = () => {
  return config(APP_ENVIRONMENT) === PROD
    ? GLOBAL_PAYMENTS_CONSTANTS?.GLOBAL_PAYMENTS_PRODUCTION
    : GLOBAL_PAYMENTS_CONSTANTS?.GLOBAL_PAYMENTS_SANDBOX;
};

const getAvsAddressPostalcodeDelivery = (checkoutDetails, AvsKey) => {
  if (AvsKey === ADDRESS) {
    return checkoutDetails?.billingAddressAndSaveCard?.defaultAddressSelected
      ? `${
          checkoutDetails?.cartData?.deliveryAddress?.addressLines?.[0] +
          checkoutDetails?.cartData?.deliveryAddress?.addressLines?.[1]
        }`
      : checkoutDetails?.billingAddressAndSaveCard?.addressLine;
  } else if (AvsKey === POSTAL_CODE) {
    return checkoutDetails?.billingAddressAndSaveCard?.defaultAddressSelected
      ? checkoutDetails?.cartData?.deliveryAddress?.pinCode ?? ''
      : checkoutDetails?.billingAddressAndSaveCard?.pincode ?? '';
  }
  return null;
};

const getAvsAddressPostalcodePickup = (checkoutDetails, AvsKey) => {
  if (AvsKey === ADDRESS) {
    return checkoutDetails?.billingAddressAndSaveCard?.addressLine;
  } else if (AvsKey === POSTAL_CODE) {
    return checkoutDetails?.billingAddressAndSaveCard?.pincode;
  }
  return null;
};

export const getAvsAddressPostalCode = (checkoutDetails, AvsKey) => {
  if (isDeliveryDisposition(checkoutDetails?.service)) {
    return getAvsAddressPostalcodeDelivery(checkoutDetails, AvsKey);
  } else if (isPickupDisposition(checkoutDetails?.service) || checkoutDetails?.isAddNewCard) {
    return getAvsAddressPostalcodePickup(checkoutDetails, AvsKey);
  }
  return null;
};

const getGlobalPaymentCreditCardFormStyle = checkoutDetails => {
  const inputLabelAlingnment = {
    top: '-25px',
    'font-size': '12px',
    'line-height': '22px',
    color: '#494949',
  };

  const gpSupportCreditCardList = ['visa', 'mastercard', 'amex', 'diners', 'discover', 'jcb'];

  const cardIconsStyle = {};

  for (const cardType of gpSupportCreditCardList) {
    cardIconsStyle[`img.card-type-${cardType}`] = {
      content: `url(${getTenderIcon(checkoutDetails?.tendersContent, cardType)})`,
      width: 'auto',
      height: 'auto',
    };
  }

  return {
    ...cardIconsStyle,

    'div#secure-payment-field-wrapper': {
      position: 'relative',
      margin: '1.5em 0',
      'margin-bottom': '0px',
    },
    'div#field-validation-wrapper span#field-validation-message': {
      'font-weight': 'bold',
      color: '#ff2e00',
      'font-size': '14px',
    },
    'label.offscreen': {
      top: '0px',
      width: 'fit-content',
      position: 'absolute',
      transform: 'translateY(-50%)',
      'z-index': 10,
      transition: 'all 0.2s ease',
      'font-weight': 400,
      'font-size': '16px',
      'font-family': 'National 2 Regular',
      'line-height': '24px',
    },
    input: {
      background: 'none',
      'font-weight': 500,
      'font-size': '16px',
      border: '0px',
      'border-block': 'none',
      'border-bottom': '1px solid #494949',
      '-webkit-appearance': 'none',
      padding: 0,
      cursor: 'text',
      'padding-bottom': '10px',
      'font-family': 'National 2 Regular',
    },
    'input:focus-visible': {
      outline: 'none',
    },
    'input.invalid': {
      'border-bottom': '1px solid #e4002b',
    },

    'button[type=button]': {
      cursor: 'pointer',
      'background-color': `${checkoutDetails?.isAddNewCard ? '#202124' : '#e4002b'}`,
      color: '#e4002b00',
      height: '2.75rem',
      padding: '0 2.5rem',
      border: 'none',
      'border-radius': '1.4rem',
      'font-size': '0.9rem',
      'line-height': '2.75rem',
      'font-family': 'National 2 Regular',
      'font-style': 'normal',
      'font-weight': 500,
      'text-align': 'center',
      display: 'inline-block',
      position: 'relative',
      'transition-property': 'background-color, color',
      'transition-duration': '0.35s',
      'transition-timing-function': 'cubic-bezier(0.15, 0.45, 0.6, 1)',
      'z-index': '-1',
    },
    'button[type=button]:hover': {
      border: 'none',
      cursor: 'pointer',
    },

    'div#secure-payment-field-wrapper:has(input:focus) label.offscreen': inputLabelAlingnment,
    'div#secure-payment-field-wrapper:has(input.card-holder-name:not(:placeholder-shown)) label.offscreen':
      inputLabelAlingnment,
    'div#secure-payment-field-wrapper:has(input:focus-visible) label.offscreen': inputLabelAlingnment,
    'div#secure-payment-field-wrapper:has(input.valid) label.offscreen': inputLabelAlingnment,
    'div#secure-payment-field-wrapper:has(input.invalid) label.offscreen': inputLabelAlingnment,

    'div#secure-payment-field-wrapper:has(button[type=button])': {
      margin: '0px',
      'margin-bottom': '15px',
      cursor: 'pointer',
    },
    'div#secure-payment-field-wrapper:has(button[type=button]) label.offscreen': {
      color: '#FFFFFFFF',
      'text-align': 'center',
      width: '100%',
      cursor: 'pointer',
      height: '100%',
      'padding-top': '10px',
      transform: 'none',
    },
  };
};

export const getGlobalPaymentCreditCardForm = checkoutDetails =>
  window?.GlobalPayments?.ui?.form?.({
    fields: {
      'card-number': {
        placeholder: '',
        label: `${translateWithI18Next(TRANSLATE_MAPPING_KEY?.CARD_NUMBER)}*`,
        target: '#card-number',
      },
      'card-expiration': {
        placeholder: '',
        label: `${translateWithI18Next(TRANSLATE_MAPPING_KEY?.EXPIRY_DATE_YY)}*`,
        target: '#card-expiration',
      },
      'card-cvv': {
        placeholder: '',
        label: `${translateWithI18Next(TRANSLATE_MAPPING_KEY?.CVV)}*`,
        target: '#card-cvv',
      },
      'card-holder-name': {
        placeholder: ' ',
        label: `${translateWithI18Next(TRANSLATE_MAPPING_KEY?.NAME_ON_CARD)}*`,
        target: '#card-holder-name',
      },
      submit: {
        value: 'Submit',
        target: '#payment-provider-place-order-credit-card-form',
        label: `${
          checkoutDetails?.isAddNewCard
            ? translateWithI18Next(TRANSLATE_MAPPING_KEY?.SAVE)
            : translateWithI18Next(TRANSLATE_MAPPING_KEY?.PLACE_ORDER)
        }`,
      },
    },
    styles: getGlobalPaymentCreditCardFormStyle(checkoutDetails),
  });

export const updateTokenSuccessHandler = (response, updateCheckoutDetails) =>
  updateCheckoutDetails({
    globalPaymentsOneTimeResponse: response,
    showPlaceOrderLoading: false,
    globalPaymentCreditCardFieldValidationStatus: true,
  });

export const updateTokenFailureHandler = (params, response) => {
  // Show error to the consumer
  console.log('Error on Global Payment Card Form');
  if (isCurrentPageRedirectFromRNA(window.location)) {
    redirectToPaymentFailureRNA({ ...params, response });
  } else {
    redirectToPaymentFailurePage({
      ...params,
      isCardValidateFailedScreen: true,
      response,
    });
  }
};

export const gpSubmitBtnOnClickHander = (checkoutDetails, updateCheckoutDetails) => {
  checkoutDetails?.userOrderStateDispatch?.({ type: CONTEXT_REDUCER_MAPPING_KEY?.SHOW_BUCKET_LOADER, value: true });
  updateCheckoutDetails({
    showValidationError: true,
    showPlaceOrderLoading: true,
    globalPaymentPlaceOrderClick: true,
  });
};

export const getOneTimeGlobalPaymentToken = params => {
  const { checkoutDetails, updateCheckoutDetails } = params;

  const globalPaymentCreditCardForm = getGlobalPaymentCreditCardForm(checkoutDetails);

  globalPaymentCreditCardForm?.ready(() =>
    updateCheckoutDetails({
      globalPaymentCreditCardFieldValidationStatus: false,
    }),
  );

  globalPaymentCreditCardForm?.on('token-success', response =>
    updateTokenSuccessHandler(response, updateCheckoutDetails),
  );

  globalPaymentCreditCardForm?.on('token-error', response => updateTokenFailureHandler(params, response));
  globalPaymentCreditCardForm?.on('submit', 'click', () =>
    gpSubmitBtnOnClickHander(checkoutDetails, updateCheckoutDetails),
  );
};

export const dispatchGlobalPaymentsGenerateToken = (checkoutDetails, updateCheckoutDetails, props) => {
  (checkoutDetails?.isClickAddPayment || checkoutDetails?.isAddNewCard) &&
    checkoutDetails?.dispatch(
      globalPaymentsGenerateToken(
        {
          tenantId: checkoutDetails?.tenantId,
          overrideRequestHeader: true,
          authToken: checkoutDetails?.RNAtoken,
          appchannel: checkoutDetails?.appchannel,
          accessTokenUsage: SINGLE,
        },
        param => {
          window?.GlobalPayments?.configure?.({
            accessToken: param?.clientToken,
            env: getGlobalPaymentsEnvironment(),
            fieldValidation: {
              enabled: true,
              characterValidation: 'englishOnly',
            },
          });
          updateCheckoutDetails({
            globalPaymentsAccesstoken: param?.clientToken,
            enablePaymentCardInputPanel: true,
            isPaymentTypeSelected: true,
          });
        },
        error => {
          if (isCurrentPageRedirectFromRNA(window.location)) {
            redirectToPaymentFailureRNA({ ...props, error });
          }
          redirectToPaymentFailurePage({ ...props, isCardValidateFailedScreen: true });
        },
      ),
    );
};

export const dispatchGlobalpaymentsInitiatePaymentAction = (props, checkoutDetails) => {
  let postData = {};
  if (checkoutDetails?.tender?.name === PAYMENT_TENDER_TYPES?.CREDIT_CARD) {
    const getBrowserData = window?.GlobalPayments?.ThreeDSecure?.getBrowserData();
    if (getBrowserData) {
      getBrowserData.colorDepth = GLOBAL_PAYMENTS_BROWSER_DATA_VALUES?.COLOR_DEPTH; //-- need remove
    }
    postData = {
      token: checkoutDetails?.globalPaymentsOneTimeResponse?.paymentReference,
      accessToken: checkoutDetails?.globalPaymentsAccesstoken,
      tenderType: checkoutDetails?.tender?.name,
      orderId: checkoutDetails?.orderId,
      saveCardId: checkoutDetails?.cardid,
      IssaveCard: checkoutDetails?.billingAddressAndSaveCard?.enabledSaveCard, // --- if logged in
      isStore3DS: false, //--- need to check
      threeDSServerId:
        checkoutDetails?.globalPaymentCheck3DsVersion?.enrolled === GLOBAL_PAYMENTS_CONSTANTS?.ENROLLED
          ? checkoutDetails?.globalPaymentCheck3DsVersion?.serverTransactionId
          : '',
      address: getAvsAddressPostalCode(checkoutDetails, ADDRESS),
      postalcode: getAvsAddressPostalCode(checkoutDetails, POSTAL_CODE),
      browserData: getBrowserData,
      cardNickName: checkoutDetails?.cardid
        ? checkoutDetails?.cardNickName?.trim?.()
        : checkoutDetails?.billingAddressAndSaveCard?.nickName?.trim?.(),
      cardExpMonth: checkoutDetails?.cardid
        ? checkoutDetails?.expiryDate?.substr(0, 2).trim?.()
        : checkoutDetails?.globalPaymentsOneTimeResponse?.details?.expiryMonth,
      cardExpYear: checkoutDetails?.cardid
        ? checkoutDetails?.expiryDate?.substr(2).trim?.()
        : checkoutDetails?.globalPaymentsOneTimeResponse?.details?.expiryYear,
      nameOnCard: checkoutDetails?.cardid
        ? checkoutDetails?.nameOnCard?.trim?.()
        : checkoutDetails?.globalPaymentsOneTimeResponse?.details?.cardholderName,
      cvv: checkoutDetails?.savedCardCvv,
    };
  } else {
    postData = {
      token: checkoutDetails?.globalPaymentsOneTimeResponse?.paymentReference,
      amount: checkoutDetails?.finalAmount?.toString(),
      tenderType: checkoutDetails?.tender?.name,
      orderId: checkoutDetails?.orderId,
      customerId: '',
      paymentId: '',
    };
  }
  const initaitePaymentPayload = {
    data: postData,
    tenantId: checkoutDetails?.tenantId,
    overrideRequestHeader: true,
    authToken: checkoutDetails?.RNAtoken,
    appchannel: checkoutDetails?.appchannel,
  };
  checkoutDetails?.dispatch(
    globalpaymentsInitiatePayment(
      initaitePaymentPayload,
      response => paymentTrancationSuccessHandler(props, response),
      error => paymentTrancationErrorHandler(props, error),
    ),
  );
};

export const checkGlobalPayment3DsVersion = async (checkoutDetails, updateCheckoutDetails, props) => {
  loadScript(
    `${window.location.origin}/${config(GLOBAL_PAYMENTS_3DS_FILE_URL)}`,
    GLOBAL_PAYMENTS_CONSTANTS?.GLOBAL_PAYMENTS_3DS_SCRIPT_ID,
    async () => {
      try {
        const authToken = await AuthManager.getInstance().checkAndGetAuthToken();
        const versionCheckData = await window?.GlobalPayments?.ThreeDSecure?.checkVersion(
          gloablPaymentCheckVersionUrl?.url,
          get3dspayload(checkoutDetails),
          {
            'x-tenant-id': checkoutDetails?.tenantId,
            ...networkConfig?.getXCorelationHeader(),
            Authorization: 'Bearer ' + authToken,
          },
        );

        updateCheckoutDetails({ globalPaymentCheck3DsVersion: versionCheckData });
      } catch (error) {
        get3dsError(props, error);
      }
    },
  );
};

export const initiateGlobalPayment3DsAuthentication = async (checkoutDetails, updateCheckoutDetails, props) => {
  const getBrowserData = window?.GlobalPayments?.ThreeDSecure?.getBrowserData();
  try {
    const authToken = await AuthManager.getInstance().checkAndGetAuthToken();
    if (getBrowserData) {
      getBrowserData.colorDepth = GLOBAL_PAYMENTS_BROWSER_DATA_VALUES?.COLOR_DEPTH; //--- color
      getBrowserData.acceptHeader = GLOBAL_PAYMENTS_BROWSER_DATA_VALUES?.ACCEPT_HEADER;
    }

    const authenticationData = await window?.GlobalPayments?.ThreeDSecure?.initiateAuthentication(
      gloablPayment3DSIntitiateAuthUrl?.url,
      {
        token: !checkoutDetails?.cardid ? checkoutDetails?.globalPaymentsOneTimeResponse?.paymentReference : '',
        orderId: checkoutDetails?.orderId,
        serverTransactionId: checkoutDetails?.globalPaymentCheck3DsVersion?.serverTransactionId,
        browserData: getBrowserData,
        saveCardId: checkoutDetails?.cardid,
        address: getAvsAddressPostalCode(checkoutDetails, ADDRESS),
        postalcode: getAvsAddressPostalCode(checkoutDetails, POSTAL_CODE),
        challengeWindow: {
          windowSize: window?.GlobalPayments?.ThreeDSecure?.ChallengeWindowSize?.Windowed600x400,
          displayMode: 'lightbox',
        },
      },
      {
        'x-tenant-id': checkoutDetails?.tenantId,
        ...networkConfig?.getXCorelationHeader(),
        Authorization: 'Bearer ' + authToken,
      },
    );

    updateCheckoutDetails({ globalPaymentAuthenticationData: authenticationData });
  } catch (error) {
    paymentTrancationErrorHandler({ ...props }, error);
  }
};

export const dispatchGlobalPaymentSaveCardDipatch = (props, response) => {
  const { checkoutDetails } = props;
  const { dispatch } = checkoutDetails;

  const payload = {
    savecard: {
      customerId: checkoutDetails?.customerId,
      accessToken: checkoutDetails?.globalPaymentsAccesstoken,
      token: checkoutDetails?.globalPaymentsOneTimeResponse?.paymentReference,
      cardNickName: checkoutDetails?.billingAddressAndSaveCard?.nickName?.trim?.(),
      nameOnCard: checkoutDetails?.globalPaymentsOneTimeResponse?.details?.cardholderName?.trim?.(),
      maskedCardNumber: checkoutDetails?.globalPaymentsOneTimeResponse?.details?.cardLast4,
      address: getAvsAddressPostalCode(checkoutDetails, ADDRESS),
      postalcode: getAvsAddressPostalCode(checkoutDetails, POSTAL_CODE),
      cardExpMonth: checkoutDetails?.globalPaymentsOneTimeResponse?.details?.expiryMonth,
      cardExpYear: checkoutDetails?.globalPaymentsOneTimeResponse?.details?.expiryYear,
      type: checkoutDetails?.globalPaymentsOneTimeResponse?.details?.cardType,
    },
    tenantId: checkoutDetails?.tenantId,
  };
  dispatch(
    GlobalpaymentSaveCard(
      payload,
      params => {
        if (params.sucesss) {
          const reqPayload = {
            updateUserData: true,
          };
          dispatch(getCustomerInfo(reqPayload));
          redirectToAccountPage(props, 'myPaymentMethods');
        } else {
          redirectToPaymentFailurePage({ ...props, isCardValidateFailedScreen: true, isAddNewCard: true });
        }
      },
      error => {
        redirectToPaymentFailurePage({ ...props, isCardValidateFailedScreen: true, isAddNewCard: true });
      },
    ),
  );
};

export const getLastUsedPaymentMethod = checkoutDetails => {
  const { lastUsedPaymentMethod = '', allowedTenders = [] } = checkoutDetails;
  const paymentMethodPresent = checkPaymentMethod(allowedTenders, lastUsedPaymentMethod);
  if (paymentMethodPresent) {
    return lastUsedPaymentMethod === PAYMENT_TENDER_TYPES?.PAY_BY_CASH
      ? {
          name: PAYMENT_TENDER_TYPES?.CREDIT_CARD,
          payload: { tender: PAYMENT_TENDER_TYPES?.CREDIT_CARD },
        }
      : {
          name: lastUsedPaymentMethod,
          payload: { tender: lastUsedPaymentMethod },
        };
  }
  return {
    name: '',
    payload: { tender: '' },
  };
};

export const getSavedCardId = checkoutDetails => {
  const { lastUsedPaymentMethod = '', allowedTenders = [] } = checkoutDetails;
  const paymentMethodPresent = checkPaymentMethod(allowedTenders, lastUsedPaymentMethod);
  return paymentMethodPresent &&
    (lastUsedPaymentMethod === PAYMENT_TENDER_TYPES?.CREDIT_CARD ||
      lastUsedPaymentMethod === PAYMENT_TENDER_TYPES?.PAY_BY_CASH)
    ? checkoutDetails?.savedCards?.[0]?.id
    : '';
};

export const updateBillingAddressDetails = checkoutDetails => {
  const isItSavedCard = getSavedCardId(checkoutDetails);
  const savedCard = checkoutDetails?.savedCards[0];
  const { billingAddressAndSaveCard } = checkoutDetails;
  return isItSavedCard
    ? {
        ...billingAddressAndSaveCard,
        addressLine: `${savedCard?.billingAddress?.addressLines[0]}`,
        pincode: savedCard?.billingAddress?.pinCode,
      }
    : { ...billingAddressAndSaveCard };
};

export const get3dsError = (props, error) => {
  const getErrorCode = error && error?.reasons && JSON.parse(error?.reasons[2]?.message)?.errorCode;
  if (
    getErrorCode === GLOBAL_PAYMENTS_VERIFICATION_ERROR_CODE?.OSPS019 ||
    getErrorCode === GLOBAL_PAYMENTS_VERIFICATION_ERROR_CODE?.OSPS020 ||
    getErrorCode === GLOBAL_PAYMENTS_VERIFICATION_ERROR_CODE?.OSPS022
  ) {
    if (isCurrentPageRedirectFromRNA(window.location)) {
      redirectToPaymentFailureRNA({ ...props, error });
    }
    redirectToPaymentFailurePage({
      ...props,
      isCardValidateFailedScreen: true,
      error,
    });
  } else {
    paymentTrancationErrorHandler({ ...props }, error);
  }
};

export const get3dspayload = checkoutDetails => {
  return {
    token: checkoutDetails?.globalPaymentsOneTimeResponse?.paymentReference,
    orderId: checkoutDetails?.orderId,
    saveCardId: checkoutDetails?.cardid,
    firstname: !checkoutDetails?.cardid ? checkoutDetails?.userFirstName?.value : null,
    last_name: !checkoutDetails?.cardid ? checkoutDetails?.userLastName?.value : null,
    address: !checkoutDetails?.cardid ? getAvsAddressPostalCode(checkoutDetails, ADDRESS) : null,
    postalcode: !checkoutDetails?.cardid ? getAvsAddressPostalCode(checkoutDetails, POSTAL_CODE) : null,
  };
};

export const getPaymentProviderPlaceOrderBtn = params =>
  window?.GlobalPayments && (
    <>
      {params?.checkoutDetails?.tender?.name === PAYMENT_TENDER_TYPES?.CREDIT_CARD && (
        <div id='payment-provider-place-order-credit-card-form' className='payment-provider-place-order-form'></div>
      )}
      {params?.checkoutDetails?.tender?.name === PAYMENT_TENDER_TYPES?.APPLE_PAY && (
        <div id='payment-provider-place-order-apple-pay-form' className='payment-provider-place-order-form'></div>
      )}
      {params?.checkoutDetails?.tender?.name === PAYMENT_TENDER_TYPES?.GOOGLE_PAY && (
        <div id='payment-provider-place-order-google-pay-form' className='payment-provider-place-order-form'></div>
      )}
    </>
  );

export const generatePOTToken = params => {
  const { checkoutDetails, updateCheckoutDetails, props } = params;

  if (checkoutDetails?.isAddNewCard) {
    if (isCurrentPageRedirectFromRNA(window.location)) {
      window.ReactNativeWebView.postMessage(JSON.stringify({ success: checkoutDetails }));
    } else {
      checkoutDetails?.globalPaymentsOneTimeResponse?.paymentReference && redirectToProcessingPage(props);
    }
  } else {
    checkoutDetails?.globalPaymentsOneTimeResponse?.paymentReference &&
      updateClickPlaceOrderState(checkoutDetails, updateCheckoutDetails);
  }
};

export const redirectToPaymentFailureRNA = params => {
  const { checkoutDetails, error, response } = params;
  if (checkoutDetails?.isAddNewCard) {
    window.ReactNativeWebView.postMessage(JSON.stringify({ error: error ? error : response }));
  } else {
    paymentTrancationErrorHandler({ ...params, isCardValidateFailedScreen: true }, error);
  }
};

export const updateCreditCardValidationsStauts = params => {
  const { checkoutDetails, updateCheckoutDetails, props, setPlaceOrderClick = false } = params;
  if (checkoutDetails?.globalPaymentPlaceOrderClick) {
    if (
      checkoutDetails?.globalPaymentCreditCardFieldValidationStatus &&
      checkoutDetails?.globalPaymentsOneTimeResponse?.paymentReference &&
      getAvsAddressPostalCode(checkoutDetails, PAYMENT_CREDIT_DEBIT_CARD_VIEW_TEMPLATES?.ADDRESS) !== '' &&
      getAvsAddressPostalCode(checkoutDetails, PAYMENT_CREDIT_DEBIT_CARD_VIEW_TEMPLATES?.POSTAL_CODE) !== '' &&
      (checkOutDefaultAddressSelected(checkoutDetails) ||
        (validateBillingAddress(checkoutDetails) && validateBillingPostalCode(checkoutDetails)))
    ) {
      checkoutDetails?.userOrderStateDispatch?.({ type: CONTEXT_REDUCER_MAPPING_KEY?.SHOW_BUCKET_LOADER, value: true });
      updateCheckoutDetails({
        globalPaymentPlaceOrderClick: false,
        showPlaceOrderLoading: false,
        showBillAddressValidationError: false,
      });
      generatePOTToken({ checkoutDetails, updateCheckoutDetails, props });
    } else {
      updateCheckoutDetails({
        globalPaymentCreditCardFieldValidationStatus: false,
        showPlaceOrderLoading: false,
        showBillAddressValidationError: true,
      });

      if (setPlaceOrderClick) {
        checkoutDetails?.userOrderStateDispatch?.({
          type: CONTEXT_REDUCER_MAPPING_KEY?.SHOW_BUCKET_LOADER,
          value: false,
        });
        updateCheckoutDetails({ globalPaymentPlaceOrderClick: false });
      }
    }
  }
};
